<template>
  <a-card :bordered="false" class="card-area">
    <div>
      <div class="operator">
        <a-button v-hasPermission="['dept:add']" type="primary" ghost @click="add">新增</a-button>
        <a-button v-hasPermission="['dept:delete']" @click="batchDelete">删除</a-button>
        <a-button v-hasPermission="['dept:export']" @click="exportExcel">导出Excel</a-button>
      </div>
      <!-- 表格区域 -->
      <a-table bordered :defaultExpandAllRows="true" :columns="columns"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               :scroll="{ x: 900 }"
               :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
               @change="handleTableChange">
        <template slot="operation" slot-scope="text, record">
          <a-icon v-hasPermission="['dept:update']" type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="edit(record)" title="修改"></a-icon>
          <a-badge v-hasNoPermission="['dept:update']" status="warning" text="无权限"></a-badge>
        </template>
      </a-table>
    </div>
    <!-- 新增部门 -->
    <dept-add
      @success="handleDeptAddSuccess"
      @close="handleDeptAddClose"
      :deptAddVisiable="deptAddVisiable">
    </dept-add>
    <!-- 修改部门 -->
    <dept-edit
      ref="deptEdit"
      @success="handleDeptEditSuccess"
      @close="handleDeptEditClose"
      :deptEditVisiable="deptEditVisiable">
    </dept-edit>
  </a-card>
</template>

<script>
import RangeDate from '@/components/datetime/RangeDate'
import DeptAdd from './DeptAdd'
import DeptEdit from './DeptEdit'
import {getEatonRootIds} from './../../../utils/tools'

export default {
  name: 'Dept',
  components: {DeptAdd, DeptEdit, RangeDate},
  data () {
    return {
      advanced: false,
      dataSource: [],
      selectedRowKeys: [],
      queryParams: {},
      sortedInfo: null,
      pagination: {
        defaultPageSize: 10000000,
        hideOnSinglePage: true,
        indentSize: 100
      },
      loading: false,
      deptAddVisiable: false,
      deptEditVisiable: false
    }
  },
  computed: {
    columns () {
      let { sortedInfo } = this
      sortedInfo = sortedInfo || {}
      return [{
        title: '名称',
        dataIndex: 'text'
      }, {
        title: '排序',
        dataIndex: 'order',
        width: 65,
      }, {
        title: '创建时间',
        dataIndex: 'createTime',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'createTime' && sortedInfo.order,
        customRender: (text) => { return this.datetime(text) },
      }, {
        title: '修改时间',
        dataIndex: 'modifyTime',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'modifyTime' && sortedInfo.order,
        customRender: (text) => { return this.datetime(text) },
      }, {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        fixed: 'right'
      }]
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleDeptAddClose () {
      this.deptAddVisiable = false
    },
    handleDeptAddSuccess () {
      this.deptAddVisiable = false
      this.$message.success('新增部门成功')
      this.fetch()
    },
    add () {
      this.deptAddVisiable = true
    },
    handleDeptEditClose () {
      this.deptEditVisiable = false
    },
    handleDeptEditSuccess () {
      this.deptEditVisiable = false
      this.$message.success('修改部门成功')
      this.fetch()
    },
    edit (record) {
      this.deptEditVisiable = true
      this.$refs.deptEdit.setFormValues(record)
    },
    handleDateChange (value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    batchDelete () {
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择需要删除的记录')
        return
      }
      let that = this
      this.$confirm({
        title: '确定删除所选中的记录?',
        content: '当您点击确定按钮后，这些记录将会被彻底删除，如果其包含子记录，也将一并删除！',
        centered: true,
        onOk () {
          that.$delete('dept/' + that.selectedRowKeys.join(',')).then(() => {
            that.$message.success('删除成功')
            that.selectedRowKeys = []
            that.fetch()
          })
        },
        onCancel () {
          that.selectedRowKeys = []
        }
      })
    },
    exportExcel () {
      let {sortedInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.$export('dept/excel', {
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams
      })
    },
    search () {
      let {sortedInfo} = this
      let sortField, sortOrder
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field
        sortOrder = sortedInfo.order
      }
      this.fetch({
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams
      })
    },
    reset () {
      // 取消选中
      this.selectedRowKeys = []
      // 重置列排序规则
      this.sortedInfo = null
      // 重置查询参数
      this.queryParams = {}
      // 清空时间选择
      this.$refs.createTime.reset()
      this.fetch()
    },
    handleTableChange (pagination, filters, sorter) {
      this.sortedInfo = sorter
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.queryParams,
        ...filters
      })
    },
    fetch (params = {}) {
      this.loading = true
      this.$get("dept", {
        ...params,
        rootDeptIds: getEatonRootIds()
      }).then((r) => {
        let data = r.data
        console.log("部门==>", data);
        this.loading = false
        this.dataSource = data.rows.children
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>
